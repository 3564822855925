.App {
  text-align: left;
}

.App-logoMain {
  height: 5vmin;
  pointer-events: none;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 60s linear;
  }
}

.App-header2 {
  background-color: #0e3b5f;
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* unvisited link 
a:link {
  color: '#ffffff';
  font-style: italic;
}

// visited link 
a:visited {
  color: '#ffffff';
  font-style: italic;
}

// mouse over link 
a:hover {
  color: '#aaaaaa';
}

// selected link 
a:active {
  color: '#ffffff';
}
*/


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}